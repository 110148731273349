
/* Facts Section
--------------------------------*/
#facts {
  background: #f7f7f7;
  padding: 80px 0 60px 0;
}

.btn-rounded {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #2dc997;
    color: #2dc997;
}

.btn-rounded:hover {
  background: #2dc997;
  color: #fff;
  border: 2px solid #2dc997;
}


