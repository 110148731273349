
#main {
  margin: 0 1rem
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  overflow: hidden;
}

/* Sections Header
--------------------------------*/
.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
}

.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: #999;
}

.table-heading {
  text-align: center;
  margin: 1rem;
  font-weight: 700;
}
/* Services Section
--------------------------------*/
#services {
  background: #fff;
  background-size: cover;
  padding: 80px 0 60px 0;
}

.letter {
  /* background-image: url("/assets/img/letter.png"); */
  background-repeat: no-repeat;
  background-size: 10rem;
  background-position: calc(100% - 1rem) calc(100% - 1rem);
  padding: 2rem;
  background-color: beige;
  border-radius: 20px;
}
